
import Layout from 'components/Layout';
import Schedule from '../../components/Schedule';
import {
  dropdownInterface,
  dataColumnInterface,
  userValueTimeInterface,
} from '../../data/interfaces';

const ScheduleView = (props: iProps): JSX.Element => {
  const {
    setIsFirstStep,
    userTimeZone,
    setUserTimeZone,
    indexPage,
    setIndexPage,
    dataColumns,
    setUserValueTime,
    dataStatus,
    statusCode,
  } = props;
  return (
    <Layout>
      <Schedule
        setIsFirstStep={setIsFirstStep}
        userTimeZone={userTimeZone}
        setUserTimeZone={setUserTimeZone}
        indexPage={indexPage}
        setIndexPage={setIndexPage}
        dataColumns={dataColumns}
        setUserValueTime={setUserValueTime}
        dataStatus={dataStatus}
        statusCode={statusCode}
      />
    </Layout>
  );
};

interface iProps {
  setIsFirstStep: (bool: boolean) => void;
  userTimeZone: dropdownInterface;
  setUserTimeZone: (str: dropdownInterface) => void;
  indexPage: number;
  setIndexPage: (num: number) => void;
  dataColumns: dataColumnInterface[];
  setUserValueTime: (userDate: userValueTimeInterface) => void;
  dataStatus: string;
  statusCode: number;
}

export default ScheduleView;
