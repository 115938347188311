import dayjs from 'dayjs';

const timezoneMap = new Map([
  ['US/Hawaii', 'Pacific/Honolulu'],
  ['US/Alaska', 'America/Anchorage'],
  ['US/Pacific', 'America/Los_Angeles'],
  ['US/Mountain', 'America/Denver'],
  ['US/Arizona', 'America/Phoenix'],
  ['US/Central', 'America/Chicago'],
  ['US/Eastern', 'America/New_York'],
]);

const invertedTimezoneMap = new Map([...timezoneMap.entries()].map(([a, b]) => [b, a]));

function findMappings(timezone: string) {
  return [
    ...new Set(
      [timezone, timezoneMap.get(timezone), invertedTimezoneMap.get(timezone)].filter(Boolean),
    ).values(),
  ] as string[];
}

export default function getSafeTz(timezone: string) {
  const timezoneMappings = findMappings(timezone);
  const date = dayjs();

  for (const t of timezoneMappings) {
    try {
      date.tz(t);
      return t;
    } catch (error) {
      // Ignores the error
    }
  }

  return null;
}
