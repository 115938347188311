import LogRocket from 'logrocket';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import './App.css';

import { ErrorFallback } from 'containers/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import { MIXPANEL_TOKEN } from './config/urls';
import ScheduleContainer from './containers/ScheduleContainer';

if (process.env.REACT_APP_NODE_ENV === 'production') {
  LogRocket.init('m7fsxw/sign-up-flow', {
    dom: {
      inputSanitizer: true,
      textSanitizer: true,
    },
    network: {
      responseSanitizer: response => {
        response.body = undefined;
        return response;
      },
      requestSanitizer: request => {
        request.body = undefined;
        return request;
      },
    },
  });
}

mixpanel.init(MIXPANEL_TOKEN);

function App(): JSX.Element {
  const location = useLocation<Location>();

  const [queryId, setQueryId] = useState<string | null>(
    new URLSearchParams(location.search).get('userId'),
  );
  const [gs, setGS] = useState<string | null>(new URLSearchParams(location.search).get('gs'));

  const redirect404 = () => {
    window.location.replace('https://app.expressable.com/signup');
  };

  useEffect(() => {
    const query: string | null = new URLSearchParams(location.search).get('userId');
    const gs = new URLSearchParams(location.search).get('gs');
    if (query) {
      mixpanel.alias(query);
    }
    setGS(gs);
    setQueryId(query);
  }, [location.search]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Switch>
        <Route path="/schedule">
          <ScheduleContainer userId={queryId} />
        </Route>

        {queryId && <Redirect to={`/schedule?userId=${queryId}&gs=${gs}`} />}

        <Route path="*">{redirect404}</Route>
      </Switch>
    </ErrorBoundary>
  );
}

export default App;
