import { ComponentPropsWithoutRef, forwardRef } from 'react';
import cx from 'classnames';

type Props = ComponentPropsWithoutRef<'label'>;

const Label = forwardRef<HTMLLabelElement, Props>(({ className, ...rest }, ref) => {
  return <label className={cx('mb-4 text-[13px] font-semibold', className)} ref={ref} {...rest} />;
});

Label.displayName = 'Button';

export default Label;
