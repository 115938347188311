import { ComponentPropsWithoutRef, forwardRef } from 'react';
import loadingIcon from '../../assets/img/loading-icon.svg';
import cx from 'classnames';

interface Props extends ComponentPropsWithoutRef<'button'> {
  isLoading?: boolean;
  variant?: 'ghost' | 'solid';
}

const Button = forwardRef<HTMLButtonElement, Props>(
  ({ className, children, isLoading, variant = 'solid', ...rest }, ref) => {
    return (
      <button
        className={cx(
          'h-14 min-w-max px-8 hover:bg-purple-300 font-semibold disabled:cursor-default',
          {
            'bg-purple-200 rounded-full border-1 border-black-12% disabled:bg-black-12% ':
              variant === 'solid',
            'px-5 underline hover:bg-purple-100 rounded-full disabled:text-black-38% disabled:hover:bg-transparent':
              variant === 'ghost',
          },
          className,
        )}
        ref={ref}
        {...rest}
      >
        {isLoading ? (
          <img src={loadingIcon} alt="Loading..." className="h-5 w-5 m-auto animate-spin" />
        ) : (
          children
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
