
import Layout from 'components/Layout';
import ScheduleConfirm from '../../components/ScheduleConfirm';
import { dropdownInterface, userValueTimeInterface } from '../../data/interfaces';

const ScheduleConfirmView = (props: iProps): JSX.Element => {
  const { isFirstStep, setIsFirstStep, userValueTime, userTimeZone, submitDataPOST } = props;

  return (
    <Layout>
      <ScheduleConfirm
        isFirstStep={isFirstStep}
        setIsFirstStep={setIsFirstStep}
        userValueTime={userValueTime}
        userTimeZone={userTimeZone}
        submitDataPOST={submitDataPOST}
      />
    </Layout>
  );
};

interface iProps {
  isFirstStep: boolean;
  setIsFirstStep: (bool: boolean) => void;
  userValueTime: userValueTimeInterface;
  userTimeZone: dropdownInterface;
  submitDataPOST: () => void;
}

export default ScheduleConfirmView;
