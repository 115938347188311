import Button from 'components/Button';
import Layout from 'components/Layout';
import LogRocket from 'logrocket';
import { useEffect } from 'react';
export const ErrorFallback = ({ error }: { error?: Error }): JSX.Element => {
  useEffect(() => {
    if (error) {
      LogRocket.captureException(error);
    }
  }, []);

  return (
    <Layout>
      <div className="flex flex-col items-start p-8 flex-grow">
        <h1 className="text-3xl font-bold">
          The app has encountered an error and it could not recover
        </h1>
        <p className="mb-4">The following text might be of use to the technical team:</p>
        <code className="p-4 bg-gray-200 mb-4">
          {error?.message ?? 'No information about the error'}
        </code>

        <a href="https://start.expressable.com/">
          <Button variant="solid" className="mt-4">
            Start over
          </Button>
        </a>
      </div>
    </Layout>
  );
};
