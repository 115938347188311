import { useState } from 'react';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faClock, faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import { dropdownInterface, userValueTimeInterface } from '../../data/interfaces';
import Button from 'components/Button';

const ScheduleConfirm = (props: iProps): JSX.Element => {
  const [dataSent, setDataSent] = useState<boolean>(false);
  const { isFirstStep, setIsFirstStep, userValueTime, userTimeZone, submitDataPOST } = props;

  return (
    <form
      className="pt-16 animated max-w-[600px] w-full"
      onSubmit={event => event.preventDefault()}
    >
      {!isFirstStep && (
        <button
          type="button"
          onClick={() => setIsFirstStep(true)}
          className="mb-6 font-semibold focus:border-indigo-600 border-2 border-transparent rounded focus:outline-none text-black-900 text-sm h-8 transform hover:-translate-x-1 transition font-sans"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
          <span className="border-b-2 border-black-900 ml-2">Back</span>
        </button>
      )}
      <div className="font-serif mt-2.5 pr-6">
        <h2 className="font-bold text-3xl mb-16">Please confirm your reservation</h2>
        <h3 className="mt-4 text-lg text-black-900 font-medium">
          <FontAwesomeIcon icon={faCalendarDays} className="h-5 w-5" />
          <span className="ml-4">
            {`${dayjs(userValueTime.dateToSubmit).format('dddd, MMMM DD, YYYY')}`}
          </span>
        </h3>

        <h3 className="mt-4 text-lg text-black-900 font-medium mb-16">
          <FontAwesomeIcon icon={faClock} className="h-5 w-5" />
          <span className="ml-4">
            {`${userValueTime.dateToShow[1]} (${userTimeZone.label.split(' ')[1]} Time)`}
          </span>
        </h3>
      </div>

      <div className="flex flex-col md:flex-row">
        <Button
          type="submit"
          className="font-semibold mr-4"
          isLoading={dataSent}
          disabled={dataSent}
          onClick={() => {
            setDataSent(true);
            submitDataPOST();
          }}
        >
          Confirm Reservation
        </Button>
        <Button variant="ghost" disabled={dataSent} onClick={() => setIsFirstStep(true)}>
          Change Your Reservation
        </Button>
      </div>
    </form>
  );
};

interface iProps {
  isFirstStep: boolean;
  setIsFirstStep: (bool: boolean) => void;
  userValueTime: userValueTimeInterface;
  userTimeZone: dropdownInterface;
  submitDataPOST: () => void;
}

export default ScheduleConfirm;
