import Button from 'components/Button';
import {
  dataColumnInterface,
  dropdownInterface,
  userValueTimeInterface,
} from '../../data/interfaces';

const ScheduleTable = (props: iProps): JSX.Element => {
  const {
    dataColumns,
    minIndexTable,
    maxIndexTable,
    setUserValueTime,
    setIsFirstStep,
    indexPage,
    totalPages,
    columns,
    userTimeZone,
    setError,
  } = props;

  // Make the logic to change the class between hidden and block;
  // i = index of the map (columns), minIndex always start at 0 and change the value to the columns number (0, 3, 6), maxIndex always start at columns value(3, 6, ...). Basically it says, show the columns from 0 to 3. Change the value with the button 'more times' and it's going to show the columns between 3 and 6. It is the same in desktop with the value of 5.
  const logicColumns = (i: number, minIndex: number, maxIndex: number) => {
    if (i < minIndex) {
      return 'hidden';
    }
    if (i > maxIndex) {
      return 'hidden';
    }
    return 'block';
  };

  return (
    <div
      className={`flex ${
        indexPage === totalPages &&
        dataColumns.length % columns < 3 &&
        dataColumns.length % columns > 0
          ? 'flex-start'
          : 'justify-between'
      }`}
    >
      {dataColumns.map((oneDay: dataColumnInterface, i: number) => {
        return (
          <div
            key={oneDay.day}
            className={`${logicColumns(i, minIndexTable, maxIndexTable)} ${
              indexPage === totalPages &&
              dataColumns.length % columns < 3 &&
              dataColumns.length % columns > 0
                ? 'pr-20'
                : ''
            }`}
          >
            <div className="text-center font-semibold text-sm">{oneDay.day}</div>
            <div className="flex flex-col mb-5" key={oneDay.dayOfMonth}>
              <div className="text-center font-semibold text-sm mb-2">{`${oneDay.month} ${oneDay.dayOfMonth}`}</div>
              {oneDay.hours.map((eachHour: string, index: number) => {
                return (
                  <Button
                    key={eachHour}
                    className="mb-2 rounded-lg"
                    type="submit"
                    variant="ghost"
                    value={`${oneDay.dateHour[index]}`}
                    onClick={event => {
                      event.preventDefault();
                      if (!userTimeZone.value) {
                        setError('Please select a time zone');
                        scrollTo(0, 0);
                        return;
                      }
                      setUserValueTime({
                        dateToSubmit: oneDay.dateHour[index],
                        dateToShow: [oneDay, eachHour],
                      });
                      setIsFirstStep(false);
                    }}
                  >
                    {eachHour}
                  </Button>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface iProps {
  dataColumns: dataColumnInterface[];
  minIndexTable: number;
  maxIndexTable: number;
  setUserValueTime: (userDate: userValueTimeInterface) => void;
  setIsFirstStep: (bool: boolean) => void;
  indexPage: number;
  totalPages: number;
  columns: number;
  userTimeZone: dropdownInterface;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

export default ScheduleTable;
