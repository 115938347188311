import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import mixpanel from 'mixpanel-browser';
import timezone from 'dayjs/plugin/timezone';
import advanceFormat from 'dayjs/plugin/advancedFormat';
import moment from 'moment-timezone';
import isBetween from 'dayjs/plugin/isBetween';
import {
  dataColumnInterface,
  dropdownInterface,
  userValueTimeInterface,
  dataToSubmitInterface,
  timesApiInterface,
} from '../../data/interfaces';
import ScheduleView from '../../views/ScheduleView';
import ScheduleConfirmView from '../../views/ScheduleConfirmView';
import timeZone from '../../data/time_zone.json';
import { API_PROXY_URL, API_URL } from '../../config/urls';
import LogRocket from 'logrocket';
import { Base64 } from 'js-base64';
import getSafeTz from 'utils/safeTz';

const ScheduleContainer = (props: iProps): JSX.Element => {
  const { userId } = props; // Query param
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(isBetween);
  dayjs.extend(advanceFormat);
  const [isFirstStep, setIsFirstStep] = useState(true); // State to start in Schedule and go to Confirm
  const [timesApi, setTimesApi] = useState<timesApiInterface>({}); // Set the data
  const [indexPage, setIndexPage] = useState<number>(1); // Index of the columns
  const userValueTimeError: userValueTimeInterface = {
    dateToShow: [
      { timezone: 'none', dayOfMonth: 0, month: 'none', day: 'none', hours: [''], dateHour: [''], safeTimezone: 'none' },
      'none',
    ],
    dateToSubmit: 'none',
  };
  const [userValueTime, setUserValueTime] = useState<userValueTimeInterface>(userValueTimeError); // Object with 2 properties: 1- Data to show  2- Data to Submit

  const [dataToSubmit, setDataToSubmit] = useState<dataToSubmitInterface>({
    dateTime: 'none',
    cueContactID: 'none',
    timeZone: 'none',
  }); // Data to send to the back end
  const [dataStatus, setDataStatus] = useState<
    'Waiting' | 'Hold' | 'Loading' | 'Success' | 'Failed'
  >('Waiting'); // State to check the GET request. Check if the status is 200
  const [statusCode, setStatusCode] = useState<number>(0); // State to check the POST request. Check if the status is 200 || 500

  // ======== TIME FUNCTIONS =========== //

  const findTimezoneOptionsByUtcOffset = (timezone: string) => {
    const date = moment();
    const timezoneArrayOffset = moment.tz(date, timezone).utcOffset();
    const appointmentTimeOffset = date?.utcOffset();

    if (dayjs.tz.guess() === 'America/Phoenix') {
      return 'US/Arizona';
    }

    return appointmentTimeOffset === timezoneArrayOffset ?? 'US/Central';
  };

  // Check if the timezone exist in the dropdown
  const getDefaultTimeZone = () => {
    const keys = Object.keys(timeZone);
    const values = Object.values(timeZone);
    const guessTimezone = values.find(findTimezoneOptionsByUtcOffset);

    const timezoneOptionValue = values.filter(
      (timezoneOption: string) => timezoneOption === guessTimezone ?? 'Select your timezone ',
    );
    const timezoneOptionLabel = keys.filter(
      (_: string, i: number) => values[i] === timezoneOptionValue[0],
    );

    return { label: timezoneOptionLabel[0], value: timezoneOptionValue[0] };
  };

  // State to set the user timezone
  const [userTimeZone, setUserTimeZone] = useState<dropdownInterface>(getDefaultTimeZone());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [globalState, _] = useState<string | null>(new URLSearchParams(location.search).get('gs'));

  const globalStepData = useMemo(() => {
    if (globalState) {
      const data = JSON.parse(Base64.decode(globalState));
      return data;
    }
  }, [globalState]);

  // Switch to get the day of the weeks
  const getDayOfWeek = (day: number) => {
    switch (day) {
      case 0:
        return 'Sunday';
        break;
      case 1:
        return 'Monday';
        break;
      case 2:
        return 'Tuesday';
        break;
      case 3:
        return 'Wednesday';
        break;
      case 4:
        return 'Thursday';
        break;
      case 5:
        return 'Friday';
        break;
      case 6:
        return 'Saturday';
        break;
      default:
        return 'none';
    }
  };

  // Switch to get the name of the months
  const getNameOfMonth = (day: number) => {
    switch (day) {
      case 0:
        return 'Jan';
        break;
      case 1:
        return 'Feb';
        break;
      case 2:
        return 'Mar';
        break;
      case 3:
        return 'Apr';
        break;
      case 4:
        return 'May';
        break;
      case 5:
        return 'Jun';
        break;
      case 6:
        return 'Jul';
      case 7:
        return 'Aug';
        break;
      case 8:
        return 'Sep';
        break;
      case 9:
        return 'Oct';
        break;
      case 10:
        return 'Nov';
        break;
      case 11:
        return 'Dec';
        break;
      default:
        return 'none';
    }
  };

  // Get the hours and parse the timezone
  const getHours = (arrOfDays: string[], timez: string) => {
    const safeTimez = getSafeTz(timez);

    return {
      hours: arrOfDays.map((eachDay: string) => {
        const eachDayTz = safeTimez ? dayjs(eachDay).tz(safeTimez) : dayjs(eachDay);
        return eachDayTz.format('h:mm A');
      }),
    };
  };

  // Get the hours without parse the timezone. We need it to send it to the backend
  const getDateHours = (arrOfDays: string[]) => {
    return {
      hoursAndDate: arrOfDays.map((eachDay: string) => {
        return eachDay;
      }),
    };
  };

  // Object with everything we need. (timezone, day, month..)
  const getAllTimes: dataColumnInterface[] = Object.keys(timesApi).map((oneDay: string) => {
    return {
      timezone: userTimeZone.value,
      dayOfMonth: dayjs(oneDay).date(),
      month: getNameOfMonth(dayjs(oneDay).month()),
      day: getDayOfWeek(dayjs(oneDay).day()),
      hours: getHours(timesApi[oneDay], userTimeZone.value).hours,
      safeTimezone: getSafeTz(userTimeZone.value),
      dateHour: getDateHours(timesApi[oneDay]).hoursAndDate,
    };
  });

  // Check if the API returns a day without hours. We don't have to show it.
  const getFilterTimes: dataColumnInterface[] = getAllTimes.filter(
    (eachDay: dataColumnInterface) => eachDay.hours.length > 0,
  );

  // ======== TIME FUNCTIONS =========== //

  useEffect(() => {
    // Get the data
    const fetchData = async () => {
      try {
        setDataStatus('Loading');
        const result = await axios.get(`${API_URL}/available-times`);
        if (result.status === 200) {
          setDataStatus('Success');
        } else {
          setDataStatus('Failed');
          LogRocket.captureMessage(`Consultation - Error ${result.status}`);
        }
        setTimesApi(result.data);
      } catch (err) {
        setDataStatus('Failed');
        LogRocket.captureMessage('Consultation - No Availability');
        LogRocket.captureException(err as Error);
      }
    };

    const objectToSubmit = (): dataToSubmitInterface => {
      const tzName = userTimeZone?.label?.split(' ')[1] ?? 'Select your time zone';

      let submitTz = '';
      if (tzName === 'Arizona') {
        submitTz = 'Mountain (Arizona / No DST Observed)';
      } else if (tzName === 'Hawaii') {
        submitTz = 'Hawaiian';
      } else if (tzName === 'Alaska') {
        submitTz = 'Alaskan';
      } else {
        submitTz = tzName;
      }

      return {
        dateTime: userValueTime.dateToSubmit,
        cueContactID: userId,
        timeZone: submitTz,
      };
    };

    if (!userTimeZone?.label || !userTimeZone.value) {
      setDataStatus('Hold');
      setDataToSubmit(objectToSubmit); // Set the state with the data
    } else {
      fetchData();
      setDataToSubmit(objectToSubmit); // Set the state with the data
    }
  }, [setTimesApi, userId, userTimeZone.label, userValueTime.dateToSubmit, userTimeZone]);

  useEffect(() => {
    if (isFirstStep) {
      mixpanel.track('Viewed - Sign Up - Schedule');
    } else {
      mixpanel.track('Viewed - Sign Up - Confirm Time');
    }
  }, [isFirstStep]);

  const getFieldsForQueryString = () => {
    return new URLSearchParams({
      cueid: userId ?? '',
      cname: globalStepData.lastName,
      me: globalStepData.ageRanges === 'Adult' ? 'yes' : 'no',
      phone: globalStepData.phone,
      email: globalStepData.email,
    }).toString();
  };

  const submitDataPOST = () => {
    axios
      .post(`${API_PROXY_URL}/salesforce/acuity/free-consultation`, dataToSubmit)
      .then(result => {
        setStatusCode(result.status);
        if (result.status === 201 && !globalStepData?.insurance) {
          window.location.href = 'https://www.expressable.com/preparing-for-your-consultation';
        } else {
          window.location.href =
            `${process.env.REACT_APP_JOTFORM_INSURANCE_URL}?${getFieldsForQueryString()}` ?? '';
        }
      })
      .catch(err => {
        setStatusCode(err.response.status);
        if (err.response.status === 500) {
          LogRocket.captureMessage('Consultation - Error when Scheduling');
        }
        if (err.response.status === 400) {
          LogRocket.captureMessage(
            'Consultation - Error when Scheduling - Time No Longer Available',
          );
        }
        LogRocket.captureException(err as Error);
        setIsFirstStep(true);
      });
  };

  return isFirstStep ? (
    <ScheduleView
      setIsFirstStep={setIsFirstStep}
      userTimeZone={userTimeZone}
      setUserTimeZone={setUserTimeZone}
      indexPage={indexPage}
      setIndexPage={setIndexPage}
      dataColumns={getFilterTimes}
      setUserValueTime={setUserValueTime}
      dataStatus={dataStatus}
      statusCode={statusCode}
    />
  ) : (
    <ScheduleConfirmView
      isFirstStep={isFirstStep}
      setIsFirstStep={setIsFirstStep}
      userValueTime={userValueTime}
      userTimeZone={userTimeZone}
      submitDataPOST={submitDataPOST}
    />
  );
};

interface iProps {
  userId: string | null;
}

export default ScheduleContainer;
