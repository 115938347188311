import { FunctionComponent } from 'react';

const Layout: FunctionComponent = ({ children }) => {
  return (
    <div className="flex flex-col items-center bg-cream-50 min-h-screen px-6">
      <a className="flex h-20 items-center" href="https://www.expressable.io">
        <img
          className="h-6"
          src={process.env.PUBLIC_URL + '/blacklogo.svg'}
          alt="expressable-logo"
        />
      </a>
      {children}
    </div>
  );
};

Layout.displayName = 'Layout';

export default Layout;
