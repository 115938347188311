import { useEffect, useState } from 'react';

import { faChevronLeft, faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/Button';
import Label from 'components/Label';
import {
  dataColumnInterface,
  dropdownInterface,
  userValueTimeInterface,
} from '../../data/interfaces';
import DropdownSchedule from '../DropdownSchedule';
import ScheduleTable from '../ScheduleTable';

const Schedule = (props: iProps): JSX.Element => {
  const {
    setIsFirstStep,
    userTimeZone,
    setUserTimeZone,
    indexPage,
    setIndexPage,
    dataColumns,
    setUserValueTime,
    dataStatus,
    statusCode,
  } = props;

  const [error, setError] = useState<string>('');
  const [userWidth, setUserWidth] = useState<number>(window.innerWidth);
  const [mobileView, setMobileView] = useState<boolean>(userWidth < 768);
  const [columns, setColumns] = useState<number>(mobileView ? 3 : 5);
  const [minIndexTable, setMinIndexTable] = useState<number>(0);
  const [maxIndexTable, setMaxIndexTable] = useState<number>(columns - 1);
  const [totalPages, setTotalPages] = useState<number>(Math.ceil(dataColumns.length / columns));

  // use Effect listening to window width
  useEffect((): (() => void) => {
    const handleResize = () => {
      setUserWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    setMobileView(userWidth < 768);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [userWidth]);

  // use Effect that set the logic. It renders if the mobile view change
  useEffect(() => {
    const flowLogic = () => {
      setColumns(mobileView ? 3 : 5);
      setTotalPages(Math.ceil(dataColumns.length / columns));
      setMaxIndexTable(columns - 1);
      setMinIndexTable(0);
      setIndexPage(1);
    };
    flowLogic();
  }, [mobileView, columns, dataColumns.length, setIndexPage]);

  const guessTimezoneLabel = () => {
    try {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return timeZone?.replace(/_/g, ' ') ?? 'default';
    } catch (error) {
      return 'default';
    }
  };

  // In case of error
  return statusCode === 500 ? (
    <form className="pt-16 max-w-[600px] w-full">
      <div className="font-serif mt-2.5 pr-6">
        <p className="text-2xl font-normal pb-2 mt-4">We&apos;re sorry</p>
        <p className="text-gray-500 font-sans mb-8 mt-4 font-normal">
          Something went wrong when scheduling.
        </p>
        <p className="text-gray-500 font-sans mb-8 font-normal">
          We will reach out to you as soon as possible and apologize for this inconvenience.
        </p>
      </div>
      <div className="lg:w-44">
        <Button
          onClick={() => {
            window.location.href = 'https://www.expressable.io/';
          }}
        >
          Back to Home
        </Button>
      </div>
    </form>
  ) : (
    <form className="pt-16 max-w-[600px] w-full flex flex-col" onSubmit={e => e.preventDefault()}>
      <div className="font-serif mt-2.5 pr-6">
        <h2 className="text-3xl font-bold">Schedule your free consultation</h2>
        {statusCode === 400 && !userTimeZone?.value ? (
          <p className="text-red-500 font-sans mb-8 font-normal">
            Please, select a time zone to see consultation times
          </p>
        ) : (
          <p className="text-gray-500 font-sans mb-16 font-normal"> </p>
        )}

        {statusCode === 400 && userTimeZone?.value ? (
          <p className="text-red-500 font-sans mb-8 font-normal">
            We&apos;re sorry, but your original selected time is no longer available. Please select
            another time.
          </p>
        ) : (
          <p className="text-gray-500 font-sans mb-16 font-normal"> </p>
        )}
      </div>
      <Label>Select Your Time Zone</Label>
      <DropdownSchedule userTimeZone={userTimeZone} setUserTimeZone={setUserTimeZone} />
      {error && <p className="text-red-500 text-xs font-sans mb-8 mt-4 font-normal">{error}</p>}
      <div
        className={`flex ${
          indexPage > 1 && indexPage < totalPages ? 'justify-between' : 'justify-end'
        } h-14`}
      >
        {indexPage > 1 ? ( // check if the user is able to back to the first page
          <button
            type="button"
            className="p-4 text-indigo-600 transform hover:-translate-x-1 transition focus:outline-none"
            onClick={() => {
              setIndexPage(indexPage - 1);
              setMinIndexTable(minIndexTable - columns);
              setMaxIndexTable(maxIndexTable - columns);
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
            Back
          </button>
        ) : (
          ''
        )}

        {indexPage < totalPages && dataColumns.length ? ( // Check if the user is able to see more times
          <button
            type="button"
            className="py-4 px-2 text-indigo-600 transform hover:translate-x-1 transition focus:outline-none"
            onClick={() => {
              setIndexPage(indexPage + 1);
              setMinIndexTable(indexPage * columns);
              setMaxIndexTable(maxIndexTable + columns);
            }}
          >
            More Times
            <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
          </button>
        ) : (
          ''
        )}
      </div>
      {dataStatus === 'Success' && userTimeZone?.value && (
        <>
          {(!dataColumns[0]?.safeTimezone) && (
            <p className="text-gray-500 font-sans mb-8 font-normal">
              Times shown in the {guessTimezoneLabel()} time zone
            </p>
          )}
          <ScheduleTable
            dataColumns={dataColumns}
            minIndexTable={minIndexTable}
            maxIndexTable={maxIndexTable}
            setUserValueTime={setUserValueTime}
            setIsFirstStep={setIsFirstStep}
            indexPage={indexPage}
            totalPages={totalPages}
            columns={columns}
            userTimeZone={userTimeZone}
            setError={setError}
          />
        </>
      )}
      {dataStatus === 'Loading' && (
        <FontAwesomeIcon icon={faSpinner} className="h-5 w-5 m-auto animate-spin text-purple-900" />
      )}

      {dataStatus === 'Hold' && (
        <p className="text-gray-500 font-sans mb-8 font-normal">
          Please select a time zone to see available times
        </p>
      )}

      {(dataStatus === 'Failed' ||
        (dataColumns.length === 0 && dataStatus !== 'Loading' && dataStatus !== 'Hold')) && (
        // In case that we dont have days to show
        <div>
          <p className="text-gray-500 font-sans mb-8 font-normal">
            We&apos;re sorry, but there are currently no available times. We&apos;ve added you
            automatically to our waitlist and will send you an SMS as soon as we have more
            availabilty
          </p>
          <Button
            onClick={() => {
              window.location.href = 'https://www.expressable.io/';
            }}
          >
            Back to Home
          </Button>
        </div>
      )}
    </form>
  );
};

interface iProps {
  setIsFirstStep: (bool: boolean) => void;
  userTimeZone: dropdownInterface;
  setUserTimeZone: (str: dropdownInterface) => void;
  indexPage: number;
  setIndexPage: (num: number) => void;
  dataColumns: dataColumnInterface[];
  setUserValueTime: (userDate: userValueTimeInterface) => void;
  dataStatus: string;
  statusCode: number;
}

export default Schedule;
