import timeZone from '../../data/time_zone.json';
import { dropdownInterface } from '../../data/interfaces';
import Dropdown from 'components/Dropdown/Dropdown';

const TIMEZONE_OPTIONS: dropdownInterface[] = (() =>
  Object.entries(timeZone).map(([key, value]) => {
    return { label: key, value };
  }))();

const DropdownSchedule = (props: iProps): JSX.Element => {
  const { userTimeZone, setUserTimeZone } = props;

  return (
    <Dropdown
      options={TIMEZONE_OPTIONS}
      placeholder="Select your time zone"
      defaultValue={userTimeZone ?? { label: 'Select your time zone' }}
      onChange={value => {
        return value ? setUserTimeZone(value) : setUserTimeZone({ label: 'none', value: 'none' });
      }}
    />
  );
};

interface iProps {
  userTimeZone: dropdownInterface;
  setUserTimeZone: (timezone: dropdownInterface) => void;
}

export default DropdownSchedule;
